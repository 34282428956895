<template>
  <div>
    <LayoutsAppHeader :options="headerOptions" />
    <div class="lg:p-6 lg:pb-0">
      <header
        class="relative flex rounded-2xl bg-[length:75%] bg-center bg-no-repeat pb-6 pt-10 md:bg-right-top lg:aspect-[2.3] lg:items-center lg:overflow-hidden xl:aspect-[2.5]"
        :style="{backgroundColor: page.template.header_color_1}"
      >
        <PatternsPaint
          :color="page.template.header_color_2"
          class="absolute inset-0 overflow-hidden rounded-2xl md:hidden"
        />
        <NuxtPicture
          v-if="page.template?.header_image?.[0]"
          :src="page.template.header_image?.[0].url"
          alt="Header image"
          class="absolute inset-0 h-full w-full object-cover max-lg:hidden"
        />
        <div
          class="absolute inset-0 right-1/4 max-lg:hidden lg:right-1/2"
          :style="`background: linear-gradient(90deg, ${page.template.header_color_2} 0%, ${page.template.header_color_2} 50%, rgba(255,255,255,0) 50%);`"
        >
          <div
            class="mask mask-left bg-purple h-full w-full"
            :style="{backgroundColor: page.template.header_color_2}"
          />
        </div>
        <div class="container relative w-full space-y-12 lg:mt-10 lg:space-y-0">
          <div class="lg:w-2/5">
            <h1 class="text-3xl font-bold leading-tight text-white md:text-6xl md:leading-tight">
              {{ page.template.header_title }}
            </h1>
            <div class="mt-6 text-white md:text-xl">
              {{ page.template.header_subtitle }}
            </div>
          </div>
          <BaseNavigation
            v-if="menu"
            :menu="menu"
            class="lg:hidden"
          />
        </div>
        <div
          v-if="page.template.carousel?.length"
          class="pointer-events-none z-0 md:container max-sm:hidden md:absolute md:inset-0"
        >
          <div class="pointer-events-auto ml-auto h-full lg:w-1/2">
            <swiper
              class="linear !pt-16"
              v-bind="swiperConfig"
            >
              <swiper-slide
                v-for="(image, index) in page.template.carousel"
                :key="index"
                class="group"
              >
                <div class="ml-auto w-3/4 group-odd:w-5/12">
                  <NuxtPicture
                    class="mask aspect-1 object-cover"
                    :src="image.url"
                    alt=""
                  />
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </header>
    </div>
    <div class="container">
      <BaseNavigation
        v-if="menu"
        :menu="menu"
        class="max-lg:hidden"
      />
      <main
        class="first:pt-20"
        :class="{'pb-20' : !noBottomPadding}"
      >
        <slot />
      </main>
    </div>
    <LayoutsAppFooter />
  </div>
</template>

<script setup lang="ts">
import {Swiper, SwiperSlide} from 'swiper/vue';

const route = useRoute();

const headerOptions = computed(() => ({absolute: true}));
const background = computed(() => route?.meta?.bg || 'bg-cream');
const menu = computed(() => route?.meta?.menu);
const noBottomPadding = computed(() => route?.meta?.noBottomPadding);

const page = computed(() => (route.meta.page));

useHead({
  bodyAttrs: {
    class: background,
  },
});

const swiperConfig = {
  centeredSlides: true,
  loop: true,
  freeMode: true,
  slidesPerView: 'auto',
  direction: 'vertical',
  spaceBetween: 10,
  speed: 5000,
  // modules: [Autoplay, FreeMode],
  autoplay: {
    delay: 0,
    duration: 2000,
    disableOnInteraction: false,
  },
};
</script>
